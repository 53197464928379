<template>
  <el-container class="home-container">
    <el-aside class="el-aside rr-sidebar hidden-xs-only" style="height: 100%;background-color: #545c64">
        <Header :navActive='navActive'/>
    </el-aside>
    <el-container class="con-right">
      <el-main>
      <Breadcrumb/>

<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>自动重发</el-breadcrumb-item>
  <el-breadcrumb-item>重发记录</el-breadcrumb-item>
</el-breadcrumb>

<!-- 内容 开始 -->
<el-card class="box-card mt20">

    <!-- 搜索区域 -->
    <el-form :inline="true" class="demo-form-inline">
      <div class="demo-input-suffix">
          <el-form-item label="请选择">
              <el-select v-model="userCategorys" placeholder="任务列表" @change="taskClick">
                <el-option
                  key="0"
                  label="全部"
                  value="0">
                  </el-option>
                <el-option
                  v-for="item in tableData2"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
                  <span style="float: left">{{ item.title }}</span>
                  <span style="float: right; color: var(--el-text-color-secondary); font-size: 13px">{{ item.num }}</span>
                  </el-option>
              </el-select>
          </el-form-item>
          <el-button type="danger" @click="taskDelClick">删除任务</el-button>
          <span class="fm">
          <el-form-item label="请选择">
            <el-select v-model="page_size" placeholder="分页数量" @change="taskListPageClick">
              <el-option label="10" value="10"></el-option>
              <el-option label="20" value="20"></el-option>
            </el-select>
          </el-form-item>
          </span>
      </div>
    </el-form>

    <el-descriptions class="margin-top mt20" border :title="tableData1.title" :column="2" v-show="id > 0">
      <template #extra>
        <el-button type="primary" size="small" @click="taskUpdateClick">编辑任务</el-button>
        <el-button type="primary" size="small" @click="taskListDelClick">删除任务产品</el-button>
      </template>
      <el-descriptions-item label="任务执行时间">{{ tableData1.task_time }}</el-descriptions-item>
      <el-descriptions-item label="产品数量">{{ tableData1.num }}</el-descriptions-item>
      <el-descriptions-item label="最后重发时间">{{ tableData1.update_time }}</el-descriptions-item>
      <el-descriptions-item label="添加时间">{{ tableData1.create_time }}</el-descriptions-item>
    </el-descriptions>

    <!-- 排序 -->
    <!-- <el-form :inline="true" class="demo-form-inline">
    <div class="demo-input-suffix">
      <el-form-item label="排序">
          <el-select v-model="order" placeholder="请选择排序方式" @change="taskOrderClick">
            <el-option
              key="1"
              label="添加时间排序"
              value="1">
            </el-option>
            <el-option
              key="2"
              label="重发时间排序"
              value="2">
            </el-option>
          </el-select>
      </el-form-item>
    </div>
    </el-form> -->

    <!-- 商品列表区域 -->
    <el-table :data="tableData3" stripe style="width: 100%" @selection-change="selectionLineChangeHandle">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index"></el-table-column>
      <el-table-column label="图片" prop="image_url">
        <template #default="scope">
          <el-image
          style="width: 60px; height: 60px"
          :src="scope.row.image_url"
          fit="fit"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title"></el-table-column>
      <el-table-column label="添加时间" prop="create_time"></el-table-column>
      <el-table-column label="更新时间" prop="update_time"></el-table-column>
    </el-table>

    <!-- 分页区域 -->
    <div class="mt20">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

</el-card>
      <!-- 内容 结束 -->

<!-- 设置操作 -->
<el-form ref="ruleTaskForm" label-width="100px">
  <el-dialog title="重发计划任务编辑" v-model="dialogFormVisible" width="680px">

          <el-form-item label="活动名称" :label-width="formLabelWidth">
            <el-input v-model="title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="重发时间" :label-width="formLabelWidth">
            <el-time-picker
              v-model="time"
              placeholder="任意时间点"
              value-format='HH:mm:ss'>
            </el-time-picker>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <span class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="submitForm()">确 定</el-button>
            </span>
          </el-form-item>

  </el-dialog>
</el-form>

    </el-main>
    </el-container>
</el-container>

</template>

<script>
import { defineComponent, toRefs, reactive, onMounted } from 'vue'
import { getTaskByid, getTaskAll, editData } from 'network/task'
import { getUserLists, deleteAllByid, taskDeleteByid } from 'network/tasklist'
import { ElMessageBox, ElMessage } from 'element-plus'
import Header from 'components/common/header/Header'
import Breadcrumb from 'components/common/breadcrumb/Breadcrumb'

export default defineComponent({
  name: 'ChongfaList',
  components: {
    Header,
    Breadcrumb
  },
  setup () {
    const queryInfo = reactive({
      id: 0,
      // 当前的页数
      page: 1,
      // 当前每页显示多少条
      page_size: 10
    })

    const state = reactive({
      dataShow: false,
      // 总页数
      total: 0,
      loading: true,
      userCategorys: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      dataonLineListSelections: []
    })

    const setup = reactive({
      dialogTableVisible: false,
      dialogFormVisible: false,
      ruleTask: {
        title: ''
      },
      formLabelWidth: '120px'
    })

    const taskForm = reactive({
      taskid: '',
      title: '',
      time: ''
    })

    // 卖家查询商品列表
    const dataInfo = (queryInfo) => {
      // 复制记录
      getTaskByid(queryInfo).then(res => {
        // console.log(res)
        if (res.status === 1) {
          state.tableData1 = res.result
        } else {
          state.tableData1 = []
        }
      })
      // 任务详情列表
      getUserLists(queryInfo).then(res => {
        // console.log(res)
        if (res.status === 1) {
          state.tableData3 = res.result.data
          state.total = res.result.total
        } else {
          state.tableData3 = []
        }
      })
    }
    // 点击任务分类
    const taskClick = (tab) => {
      console.log(tab)
      queryInfo.id = tab
      dataInfo(queryInfo)
    }

    // 分页
    const taskListPageClick = (val) => {
      // console.log(`每页 ${val} 条`)
      console.log(val)
      queryInfo.page_size = val
      dataInfo(queryInfo)
    }
    const handleSizeChange = (val) => {
      // console.log(`每页 ${val} 条`)
      queryInfo.page_size = val
      dataInfo(queryInfo)
    }
    const handleCurrentChange = (val) => {
      // console.log(`当前页: ${val}`)
      queryInfo.page = val
      dataInfo(queryInfo)
    }

    const selectionLineChangeHandle = (val) => {
      // console.log(`当前页: ${val}`)
      state.dataonLineListSelections = val
    }

    // 编辑
    const taskUpdateClick = () => {
      // 查询当前任务信息
      getTaskByid({ id: queryInfo.id }).then(res => {
        // console.log(res)
        if (res.status === 1) {
          taskForm.taskid = res.result.id
          taskForm.title = res.result.title
          taskForm.time = res.result.task_time
        } else {
          ElMessage({
            type: 'info',
            message: res.result.message
          })
        }
      })
      setup.dialogFormVisible = true
    }

    // 删除任务
    const taskDelClick = (val) => {
      ElMessageBox.confirm('此操作将永久删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // console.log(queryInfo)
          // 删除复制记录
          taskDeleteByid(queryInfo).then(res => {
            // console.log(res)
            if (res.status === 1) {
              dataInfo(queryInfo)
              ElMessage({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              state.tableData = []
            }
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除'
          })
        })
    }

    // 删除
    const taskListDelClick = (val) => {
      ElMessageBox.confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const arrayPush = []
          for (var i = 0; i < state.dataonLineListSelections.length; i++) {
            // console.log('id:' + state.dataonLineListSelections[i].id)
            arrayPush.push(state.dataonLineListSelections[i].id)
          }
          console.log(arrayPush)
          // 删除复制记录
          deleteAllByid(arrayPush).then(res => {
            // console.log(res)
            if (res.status === 1) {
              dataInfo(queryInfo)
              ElMessage({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              state.tableData = []
            }
          })
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除'
          })
        })
    }

    // 确认任务表单
    const submitForm = () => {
      if (taskForm.title === '') {
        ElMessage.error('活动名称不可以为空！')
        return false
      }
      if (taskForm.countNum <= 0) {
        ElMessage.error('选择产品不可以为空！')
        return false
      }
      // console.log(taskForm)
      editData(taskForm).then(res => {
        if (res.status === 1) {
          // taskdataInfo(taskqueryInfo)
          state.tableData1.title = taskForm.title
          state.tableData1.time = taskForm.time
          setup.dialogFormVisible = false
          ElMessage.success(res.message)
        } else {
          // options.tableData = []
          ElMessage.error(res.message)
        }
      })
    }

    onMounted(() => {
      dataInfo(queryInfo)
      // 任务列表
      getTaskAll(queryInfo).then(res => {
        if (res.status === 1) {
          state.tableData2 = res.result
        } else {
          state.tableData2 = []
        }
      })
    })
    return {
      ...toRefs(setup),
      ...toRefs(state),
      ...toRefs(queryInfo),
      ...toRefs(taskForm),
      submitForm,
      dataInfo,
      taskClick,
      handleSizeChange,
      handleCurrentChange,
      taskListPageClick,
      taskListDelClick,
      selectionLineChangeHandle,
      taskDelClick,
      taskUpdateClick,
      navActive: '3-2'
    }
  }
})
</script>
<style lang="scss" scoped>
.fm{
  float:right;
}
</style>
